import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path0/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}
import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as locations1EMzLCbk3EMeta } from "/vercel/path0/pages/locations.vue?macro=true";
import { default as _91apartmentSlug_93R1oQEqMRb2Meta } from "/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue?macro=true";
import { default as bookSyrhQB7qKbMeta } from "/vercel/path0/pages/properties/[slug]/book.vue?macro=true";
import { default as indexEmc5cCZHhaMeta } from "/vercel/path0/pages/properties/[slug]/index.vue?macro=true";
import { default as _91_91pageNumber_93_93fOSSPbFl8HMeta } from "/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue?macro=true";
import { default as _91slug_93MJFUoUvQ9jMeta } from "/vercel/path0/pages/stories/[slug].vue?macro=true";
import { default as component_45stubmWydxsgktWMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmWydxsgktW } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/nl/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "locations___nl",
    path: "/nl/locations",
    component: () => import("/vercel/path0/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: "locations___en",
    path: "/locations",
    component: () => import("/vercel/path0/pages/locations.vue").then(m => m.default || m)
  },
  {
    name: "properties-slug-apartmentSlug___nl",
    path: "/nl/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue").then(m => m.default || m)
  },
  {
    name: "properties-slug-apartmentSlug___en",
    path: "/properties/:slug()/:apartmentSlug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/[apartmentSlug].vue").then(m => m.default || m)
  },
  {
    name: "properties-slug-book___nl",
    path: "/nl/properties/:slug()/book",
    meta: bookSyrhQB7qKbMeta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue").then(m => m.default || m)
  },
  {
    name: "properties-slug-book___en",
    path: "/properties/:slug()/book",
    meta: bookSyrhQB7qKbMeta || {},
    component: () => import("/vercel/path0/pages/properties/[slug]/book.vue").then(m => m.default || m)
  },
  {
    name: "properties-slug___nl",
    path: "/nl/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "properties-slug___en",
    path: "/properties/:slug()",
    component: () => import("/vercel/path0/pages/properties/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "stories-page-pageNumber___nl",
    path: "/nl/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue").then(m => m.default || m)
  },
  {
    name: "stories-page-pageNumber___en",
    path: "/stories/:page?/:pageNumber?",
    component: () => import("/vercel/path0/pages/stories/[[page]]/[[pageNumber]].vue").then(m => m.default || m)
  },
  {
    name: "stories-slug___nl",
    path: "/nl/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "stories-slug___en",
    path: "/stories/:slug()",
    component: () => import("/vercel/path0/pages/stories/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubmWydxsgktWMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubmWydxsgktW
  }
]
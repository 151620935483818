<template>
    <DelayHydration>
        <section
            class="base-section"
            v-bind="$attrs"
            :style="{
                '--background-color': backgroundColor,
                '--text-color': textColor
            }"
        >
            <div class="base-section__inner">
                <header
                    v-if="!!$slots.title || pending"
                    class="base-section__header"
                >
                    <h2
                        class="base-section__title"
                    >
                        <Skeletor
                            v-if="pending"
                        />
                        <slot
                            v-else
                            name="title"
                        />
                    </h2>
                </header>

                <header
                    v-if="!!$slots['page-title']"
                    class="base-section__header"
                >
                    <h1 class="base-section__page-title">
                        <slot name="page-title" />
                    </h1>
                </header>

                <div
                    v-if="!!$slots.intro || pending"
                    class="base-section__intro"
                >
                    <Skeletor
                        v-if="pending"
                    />
                    <slot
                        v-else
                        name="intro"
                    />
                </div>

                <article
                    v-if="!!$slots.default"
                    class="base-section__body"
                >
                    <template v-if="pending">
                        <p
                            v-for="i in [1,2,3]"
                            :key="i"
                        >
                            <Skeletor />
                            <Skeletor width="40%" />
                            <Skeletor />
                            <Skeletor width="60%" />
                            <Skeletor />
                        </p>
                    </template>

                    <slot v-else />
                </article>
            </div>
        </section>
    </DelayHydration>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { ColorPalette } from '~/composables/useColorPalette';
import { useColorPalette } from '~/composables/useColorPalette';

const { resolveColor, getTextColor } = useColorPalette();

interface Props {
    backgroundColor?: keyof ColorPalette;
    pending?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    backgroundColor: () => 'cream',
    pending: () => false
});

const backgroundColor = computed(() => resolveColor(props.backgroundColor));
const textColor = computed(() => getTextColor(props.backgroundColor));
</script>

<style lang="less">
.base-section {
    background-color: var(--background-color);
    color: var(--text-color);
    position: relative;
    top: calc(var(--banner-height) * -1);
}

.base-section__inner {
    .base-grid(var(--grid-maxWidth-page), false);
    padding-top: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
    grid-auto-rows: auto;
    row-gap: var(--spacing-sm);

    @media @q-md-min {
        row-gap: var(--spacing-lg);
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
}

.base-section__header {
    grid-column: first/right-side-gutter;
    width: 100%;
    justify-self: center;
    text-align: center;

    @media @q-md-min {
        grid-column: left-gutter/third;
    }
}

.base-section__intro {
    grid-column: first/right-side-gutter;
    width: 100%;
    justify-self: center;

    max-width: 52rem;

    text-align: center;

    &, & p {
        .typography-lead();
    }

    @media @q-md-min {
        grid-column: first/right-side-gutter;
    }
}

.base-section__body {
    grid-column: first/right-side-gutter;
    width: 100%;
    justify-self: center;

    * + & {
        margin-top: var(--spacing-lg);
    }

    @media @q-md-min {
        grid-column: left-gutter/third;
    }
}

.base-section + .base-section {
    padding-top: calc(var(--grid-gutter-margin) / 2);

    @media @q-md-min {
        padding-top: 4rem;
    }
}

.base-section__page-title {
    .typography-h2();
}

.base-section--align-left {
    .base-section__header,
    .base-section__intro,
    .base-section__body {
        grid-column: first/right-side-gutter;
        text-align: left;
        justify-self: left;

        @media @q-md-min {
            grid-column: first/right-gutter;
        }
    }
}

.base-section--split {
    @media @q-md-min {
        .base-section__inner {
            grid-template-rows: auto 1fr;
        }

        .base-section__header {
            grid-row: 1;
        }

        .base-section__intro {
            grid-row: 2;
            font-size: var(--font-size-xl);
        }

        .base-section__header,
        .base-section__intro {
            text-align: left;
            justify-self: left;
            grid-column: first/left-gutter;
        }

        .base-section__body {
            grid-row: 1/-1;
            grid-column: second/right-side-gutter;
        }
    }
}

.base-section--full-bleed {
    .base-section__body {
        grid-column: first/-1;
        width: 100%;
    }
}

.base-section--less-padding {
    @media @q-md-min {
        padding-top: var(--spacing-2xl);
        padding-bottom: var(--spacing-2xl);
    }
}
</style>

<template>
    <NuxtLayout>
        <VitePwaManifest />
        <!-- <SeoKit /> -->
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup>
// Set scrollbar width BEFORE hydration delay
useHead({
    script: [
        {
            innerHTML: 'document.addEventListener("DOMContentLoaded", (event) => {document.documentElement.style.setProperty(\'--scrollbar-width\', (window.innerWidth - document.documentElement.clientWidth) + \'px\');})'
        }
    ]
});
function calculateScrollbarWidth() {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px');
}
onBeforeMount(() => {
    window.addEventListener('resize', calculateScrollbarWidth, false);
});
</script>

<style lang="less" src="./assets/styles/global.less"></style>

<template>
    <NuxtLayout
        class="error"
        name="default"
    >
        <LazyContentHero background-color="cream">
            <template #title>
                <template v-if="error && error.statusCode === 404">
                    {{ $t('page-not-found.title') }}
                </template>
                <template v-else>
                    {{ $t('an-error-occurred') }}
                </template>
            </template>
            <template #description>
                {{ $t('page-not-found.description') }}
            </template>
        </LazyContentHero>
        <BaseSection>
            <p v-if="error && error.statusCode === 404">
                {{ $t('page-not-found.caption') }}
            </p>
            <p v-else-if="error">
                {{ error.message }}
            </p>
            <BaseButton
                class="base-button--tertiary"
                :element="NuxtLinkLocale"
                to="/"
            >
                {{ $t('go-to-homepage') }}
            </BaseButton>
        </BaseSection>
    </NuxtLayout>
</template>

<script setup>
import { consola } from 'consola';
const props = defineProps({
    error: {
        type: Object,
        required: false,
        default: null,
    },
});

const NuxtLinkLocale = resolveComponent('NuxtLinkLocale');
consola.error(props.error);
</script>

<style lang="less">
.error {
    .base-section {
        padding-top: 0;

        p {
            margin-bottom: var(--spacing-sm);
        }
    }
}
</style>

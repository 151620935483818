import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/guide/advanced/scroll-behavior.html
export default <RouterConfig> {
    scrollBehavior(to, from) {
        if (to.hash !== '') {
            // if target is anchor link, abort
            return;
        }

        if (to.path === from.path) {
            return { el: to.hash?.length ? to.hash : '#layout', top: 0 };
        }

        // always scroll to top
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ el: to.hash?.length ? to.hash : '#layout', top: 0, behavior: 'instant' });
            }, 1000);
        });
    }
};

import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin(( {$config, vueApp }) => {
    if ($config.public.gtmId) {
        useHead({
            script: [
                {
                    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${$config.public.gtmId}');`,
                    defer: true,
                    tagPriority: 'low'
                }
            ]});

        vueApp.use(
            createGtm({
                id: $config.public.gtmId,
                loadScript: false,
                debug: $config.public.appEnv === 'development', // Whether or not display console logs debugs (optional)
                vueRouter: useRouter(), // Pass the router instance to automatically sync with router (optional)
                trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
            }),
        );
    }
});

import revive_payload_client_qGFuRjgHwN from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XxUhcbWbyb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bbU7HZmyag from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VGENz5ECUf from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.1_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_NQ5vHB8Gf9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zCrHpUUKjf from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e3wEQtitmC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mSl1kt5c3n from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jvLAzt1Lw4 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.1_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_wQrZQI234r from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.1_eslint@8.57.0_less@4.2.0_rollup@4.21.1_stylelint@14.16.1_type_ebgrikrzdo32g5fbefk3hfr5ne/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_e3KoAaYqwN from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_rollup@4.21.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_iLKUi9VeWV from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_rollup@4.21.1_vite@5.4.2_vue@3.4.38/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_C17D9nrzXy from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.21.1_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_6YUvk9ni9y from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_rollup@4.21.1_vite@5.4.2_vue@3.4.38/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import motion_Cc5B6It4K7 from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.3_rollup@4.21.1_vue@3.4.38/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import nuxt_plugin_d1YptDfG9b from "/vercel/path0/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@4.21.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_woYczrSxkj from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0LLp5O6Dz1 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_YC8EiOVTmD from "/vercel/path0/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_nuxt@3.13.0_rollup@4.21.1_vue@3.4.38/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_JzhIhoAXRd from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_rollup@4.21.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pwa_icons_lfuvhU4kiY from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.4_rollup@4.21.1_vite@5.4.2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_aqWz96HkJw from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_@vite-pwa+assets-generator@0.2.4_rollup@4.21.1_vite@5.4.2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import gtm_cXsUZobmSF from "/vercel/path0/plugins/gtm.ts";
import vue_skeletor_qwqWpfYvoX from "/vercel/path0/plugins/vue-skeletor.js";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/plugins/bugsnag.ts";
export default [
  revive_payload_client_qGFuRjgHwN,
  unhead_XxUhcbWbyb,
  router_bbU7HZmyag,
  _0_siteConfig_VGENz5ECUf,
  payload_client_NQ5vHB8Gf9,
  navigation_repaint_client_zCrHpUUKjf,
  check_outdated_build_client_e3wEQtitmC,
  chunk_reload_client_mSl1kt5c3n,
  plugin_vue3_jvLAzt1Lw4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wQrZQI234r,
  titles_e3KoAaYqwN,
  defaults_iLKUi9VeWV,
  siteConfig_C17D9nrzXy,
  inferSeoMetaPlugin_6YUvk9ni9y,
  motion_Cc5B6It4K7,
  nuxt_plugin_d1YptDfG9b,
  switch_locale_path_ssr_woYczrSxkj,
  i18n_0LLp5O6Dz1,
  plugin_YC8EiOVTmD,
  plugin_JzhIhoAXRd,
  pwa_icons_lfuvhU4kiY,
  pwa_client_aqWz96HkJw,
  gtm_cXsUZobmSF,
  vue_skeletor_qwqWpfYvoX,
  bugsnag_tkMQfOjcVZ
]
import { color } from '~/assets/styles/css-variables';

export interface ColorPalette {
    'bright-orange': string;
    'cream': string;
    'lime': string;
    'oasis-green': string;
    'pale-blue': string;
    'pink': string;
}

export function useColorPalette() {
    function resolveColor(colorName: keyof ColorPalette): string {
        return color[colorName];
    }

    function getTextColor(colorName: keyof ColorPalette, lightColorFallback?: string): string {
        if (colorName === 'oasis-green') {
            // @ts-ignore
            return lightColorFallback ? color[lightColorFallback] : color.lime;
        } else {
            // @ts-ignore
            return color.darkest;
        }
    }

    return {
        resolveColor,
        getTextColor
    };
}

import Bugsnag from '@bugsnag/js';

export default defineNuxtPlugin(() => {
    Bugsnag.addOnError(function(event) {
        // Ignore errors on booking page
        if (event.errors?.[0].errorMessage?.includes('Failed to fetch dynamically imported module:')) {
            return false; // Nuxt handles these with a reload
        }
        return !(window?.location.href.includes('/book') || window?.location.href.includes('accommodation/search'));
    });
});

import { defaultTheme } from '@digitalnatives/css-variables';

/**
 * Colors - Styles
 * ====================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/color.js
 */
export const color = {
    ...defaultTheme.color,
    lightest: '#fff',
    gray: null,

    cream: '#e5e0db',
    'pale-blue': '#73808f',
    pink: '#d48080',
    'bright-orange': '#de815d',
    'oasis-green': '#293b24',
    lime: '#cccca3',

    primary: 'var(--color-lightest)',
    'primary-light': 'rgba(255, 255, 255, 0.6)',
    'primary-dark': 'rgba(255, 255, 255, 0.5)',

    secondary: 'var(--color-lime)',
    'secondary-light': 'rgba(204, 204, 163, 0.6)',
    'secondary-dark': 'rgba(204, 204, 163, 0.5)',

    tertiary: 'transparent',
    'tertiary-light': 'rgba(0, 0, 0, 0.5)',
    'tertiary-dark': 'var(--color-darkest)',

    'highlight': {
        'error': '#ce2e2e',
        'warning': '#ffffff',
        'info': '#ffffff',
        'success': '#ffffff'
    }
};

/**
 * Font - Typography
 * ==================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/typography/font.js
 */
export const font = {
    ...defaultTheme.font,
    'family': {
        'primary': ['PP Mori', 'PP Mori fallback', 'sans-serif'],
        'secondary': ['PP Fragment', 'PP Fragment fallback', 'serif']
    },
    'size': {
        'xs': '0.625rem',
        'sm': '0.75rem',
        'md': '0.875rem',
        'lg': '1rem',
        'xl': '1.125rem',
        '2xl': '1.25rem',
        '3xl': '1.375rem',
        '4xl': '1.5rem',
        '5xl': '2rem',
        '6xl': '2.5rem'
    },
    'weight': {
        primary: {
            normal: 400,
            semibold: 600,
            bold: 600
        },
        secondary: {
            normal: 400
        }
    }
};

/**
 * Line heights - Typography
 * ==================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/typography/lineHeight.js
 */
export const lineHeight = {
    xs: 1,
    md: '140%'
};

/**
 * Grid - Layout
 * ================
 *  https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/layout/grid.js
 */
export const grid = {
    'maxWidth-page': '1440px',
    'full-bleed': 'left-side-gutter / -1',
    'full-width': 'first / right-side-gutter',
    'full-height': '1 / -1',
    'column-width': '29%',
    'gutter-width': '6.5%',
    'gutter-margin': 'min(6.5vw, 93px)'
};

/**
 * Spacings - Layout
 * ================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/layout/spacing.js
 */
export const spacing = {
    ...defaultTheme.spacing,
    '2xs': '.5rem',
    'xs': '0.75rem',
    'sm': '1rem',
    'md': '1.5rem',
    'lg': '2rem',
    'xl': '3rem',
    '2xl':'4rem',
    '3xl': '4.5rem'

};

/**
 * Border radius - Styles
 * ====================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/borderRadius.js
 */
export const borderRadius = {
    ...defaultTheme.borderRadius,
    sm: '0',
    md: '3.125rem',
    lg: '6.25rem',
    xl: '6.25rem'
};

/**
 * Box shadow - Styles
 * ====================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/boxShadow.js
 */
export const boxShadow = {
    // Don't inherit any shadows ...defaultTheme.boxShadow,
    outline: '0px 0px 0px 3px rgba(0, 0, 0, 0.20)'
};

/**
 * Transitions - Styles
 * ================
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/transition.js
 */
export const transition = {
    ...defaultTheme.transition,
    timing: {
        slower: '3s',
        slow: '1s',
        medium: '400ms',
        fast: '250ms'
    },
    // Defines easing in/out timings using a custom cubic-bezier.
    timingFunction: {
        in: 'cubic-bezier(0.8, 0, 0.7, 1)',
        out: 'cubic-bezier(.1, 1, 0.2, 1)',
        'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)'
    }
};

export default {
    color,
    transition,
    spacing,
    grid,
    borderRadius,
    boxShadow,
    font,
    lineHeight
};
